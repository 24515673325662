body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mantine-Modal-inner {
  padding: 0px;
}

.mantine-Modal-content {
  max-width: 90%;
  max-height: 90%;
}

.mantine-SegmentedControl-root {
  background-color: #e0e3e5;
}
/*
.mantine-SegmentedControl-root {
  background-color: #fa5252;
}

.mantine-SegmentedControl-label {
  color: white;
}

.mantine-SegmentedControl-label:hover {
  color: white;
}
*/
